import Link from 'components/Link'

export default function BlogFeed({ title, articles }) {
  return (
    <nav className="sticky flex top-55 l:top-65 bg-pattens-blue min-h-85 l:min-h-90 w-full shadow-top-bar z-4">
      <div className="w-95 l:w-190 h-full sticky left-0 flex-none pt-15 pl-15 l:pl-40 pr-10 l:pr-40">
        <h3 className="text-13 l:text-16 font-medium">{title}</h3>
      </div>
      <div className="w-full hide-scrollbar overflow-x-auto bg-alice-blue">
        <div className="inline-block h-full py-10">
          <ul className="flex h-full whitespace-nowrap divide-x divide-pattens-blue">
            {articles.map((article) => {
              return (
                <li className="whitespace-normal" key={article._id}>
                  <Link
                    className="text-13 l:text-16 h-full flex items-baseline py-5 px-10 l:px-20 w-170 l:w-265"
                    link={article.link}
                  >
                    {article.title}
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </nav>
  )
}
