import Link from 'components/Link'
import cx from 'classnames'
import { useEffect, useState } from 'react'
import { wrap } from 'martha'

let index = -1
const colors = ['#A7CCFF', '#FF90F6', '#FFD396']

export default {
  marks: {
    internalLink: ({ children, mark }) => {
      const [color, setColor] = useState()

      useEffect(() => {
        index += 1
        setColor([colors[wrap(index, colors.length)]])
        return () => {
          index -= 1
        }
      }, [colors, wrap])

      return (
        <span className="wrap blog-rich-text transition">
          <Link link={mark}>{children}</Link>
          <style jsx>{`
            .wrap {
              box-shadow: inset 0 -1px 0 0 white, inset 0 -3px 0 0 ${color};
            }

            .wrap:hover {
              box-shadow: inset 0 -1px 0 0 white, inset 0 -0.3em 0 0 ${color};
            }
          `}</style>
        </span>
      )
    },
  },
}
