import BlogFeed from 'components/BlogFeed'
import Layout from 'components/Layout'
import BlockContent from '@sanity/block-content-to-react'
import blogIntroSerializers from 'serializers/blogIntro'
import EditorialModules from 'components/EditorialModules'
import AdvancedCta from 'modules/AdvancedCta'
import blocksToString from 'lib/blocksToString'

export default function BlogHome({ blog, globals, footer }) {
  return (
    <Layout
      globals={globals}
      footer={footer}
      canonical="/blog"
      metaTitle={blog?.metaTitle ?? `${blog.title} – Blueland`}
      metaDescription={blog?.metaDescription ?? blocksToString(blog?.intro)}
      openGraphTitle={blog?.openGraphTitle}
      openGraphDescription={blog?.openGraphDescription}
      openGraphImage={blog?.openGraphImage}
      twitterTitle={blog?.twitterTitle}
      twitterDescription={blog?.twitterDescription}
      twitterImage={blog?.twitterImage}
      allowIndexing={blog?.allowIndexing}
    >
      {blog?.articleQuickNav?.articles.length ? (
        <BlogFeed title={blog.articleQuickNav?.title} articles={blog.articleQuickNav.articles} />
      ) : null}
      <div className="pt-20 l:pt-55 px-15 s:px-25 m:px-40 l:px-60 xl:px-80 m:mb-80 l:mb-150">
        <div className="m:flex justify-between">
          {blog?.title ? (
            <h1 className="text-50 m:text-69 l:text-94 font-serif italic leading-130 mb-20 flex-none mr-50">
              {blog.title}
            </h1>
          ) : null}
          {blog?.intro ? (
            <div className="max-w-475 m:max-w-525 xl:max-w-685 m:pt-30 l:pt-40 xl:pt-50">
              <BlockContent
                renderContainerOnSingleChild
                className="text-18 l:text-20 xl:text-26 mb-20 l:mb-80"
                blocks={blog.intro}
                serializers={blogIntroSerializers}
              />
            </div>
          ) : null}
        </div>
        {blog?.editorialModules.length ? (
          <EditorialModules modules={blog.editorialModules} />
        ) : null}
      </div>
      {blog?.blogCta ? <AdvancedCta module={blog.blogCta} /> : null}
    </Layout>
  )
}
