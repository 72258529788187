import dynamic from 'next/dynamic'
import EditorialModuleHeader from 'components/EditorialModuleHeader'

const EDITORIAL_MODULES = {
  fullWidthFeatureThreeUp: dynamic(() => import('modules/FullWidthFeatureThreeUp')),
  halfWidthFeatureFourUp: dynamic(() => import('modules/HalfWidthFeatureFourUp')),
  threeUp: dynamic(() => import('modules/ThreeUp')),
}

export default function EditorialModules({ modules }) {
  if (!modules?.length) return null

  return modules.map((module) => {
    const EditorialModule = EDITORIAL_MODULES[module?._type]

    if (!EditorialModule) {
      return (
        <div
          className="text-navy-blue-muted text-opacity-50 pl-10 pt-10 last:pb-10"
          key={module._id}
        >
          missing - {module._type}
        </div>
      )
    }

    return (
      <div key={module._id}>
        <EditorialModuleHeader title={module?.title} link={module?.filter?.link} />
        <EditorialModule
          filter={module?.filter}
          slots={module?.slots}
          articles={module?.articles}
        />
      </div>
    )
  })
}
