import Media from 'components/Media'
import { screens } from 'lib/constants'
import useMedia from 'use-media'
import cx from 'classnames'
import getColorStyle from 'lib/getColorStyle'
import Button from 'components/Button'
import * as analytics from 'lib/analytics'
import { getSpacing } from './helpers'
import { useEffect, useState } from 'react'
import BlockContent from '@sanity/block-content-to-react'
import serializers from 'serializers/justLinks'

const contentWidthList = {
  extraSmall: '500px',
  small: '600px',
  medium: '715px',
  large: '875px',
  extraLarge: '1195px',
}

export default function SecondaryHero({ module }) {
  const {
    background,
    desktopBackground,
    contentWidth,
    contentAlignment,
    headline,
    headlineColor,
    body,
    bodyColor,
    mobileBgStyle,
    textMark,
    footer,
    bodyRichText,
  } = module
  const isScreenLarge = useMedia({ minWidth: screens.l })
  const isScreenSmall = useMedia({ minWidth: screens.s })
  const hasResponsiveBackground = !!(
    background?.image?.asset?.url && desktopBackground?.image?.asset?.url
  )

  const hColor = getColorStyle(headlineColor)
  const bColor = getColorStyle(bodyColor)
  const { text: textMarkTitle, textColor: textMarkColor } = textMark || {}

  const [paddingTopMobile, setPaddingTopMobile] = useState(module?.spacingTopMobile ?? 20)
  const [paddingBottomMobile, setPaddingBottomMobile] = useState(module?.spacingBottomMobile ?? 30)
  const [paddingBottomTablet, setPaddingBottomTablet] = useState(module?.spacingBottomTablet ?? 70)
  const [paddingTopTablet, setPaddingTopTablet] = useState(module?.spacingTopTablet ?? 100)
  const [paddingBottomDesktop, setPaddingBottomDesktop] = useState(module?.spacingBottomDesktop ?? 70)
  const [paddingTopDesktop, setPaddingTopDesktop] = useState(module?.spacingTopDesktop ?? 100)
  const [paddingTop, setPaddingTop] = useState(0)
  const [paddingBottom, setPaddingBottom] = useState(0)

  useEffect(() => {
    module?.spacingTopMobile !== undefined && setPaddingTopMobile(module.spacingTopMobile)
    module?.spacingBottomMobile !== undefined && setPaddingBottomMobile(module.spacingBottomMobile)
    module?.spacingBottomTablet !== undefined && setPaddingBottomTablet(module.spacingBottomTablet)
    module?.spacingTopTablet !== undefined && setPaddingTopTablet(module.spacingTopTablet)
    module?.spacingBottomDesktop !== undefined && setPaddingBottomDesktop(module.spacingBottomDesktop)
    module?.spacingTopDesktop !== undefined && setPaddingTopDesktop(module.spacingTopDesktop)
    setPaddingTop(getSpacing(paddingTopMobile, paddingTopTablet, paddingTopDesktop))
    setPaddingBottom(getSpacing(paddingBottomMobile, paddingBottomTablet, paddingBottomDesktop))
  }, [module])

  const contentWidthValue = contentWidthList[contentWidth] || contentWidthList.medium

  return (
    <div className="relative">
      {/* content */}
      <div
        className={cx(
          `w-full px-15 s:px-25  m:px-40 l:px-60 xl:px-80 relative z-1`
        )}
        style={{
          paddingBottom: `${paddingBottom}px`,
          paddingTop: `${paddingTop}px`
        }}
      >
        <div
          className={cx('flex justify-center max-w-1195 mx-auto', {
            'm:justify-start': contentAlignment === 'left',
            'm:justify-end': contentAlignment === 'right',
          })}
        >
          <div
            className={cx('text-center', {
              'mx-auto': !isScreenSmall || contentAlignment === 'center',
            })}
            style={{ maxWidth: contentWidthValue }}
          >
            <h2
              style={{ color: hColor || 'inherit' }}
              className="text-28 m:text-32 l:text-40 leading-130 font-medium"
            >
              {headline}
            </h2>
            {body && !bodyRichText ? (
              <p style={{ color: bColor || 'inherit' }} className="mt-10">
                {body}
              </p>
            ) : null}
            {bodyRichText ? (
              <div style={{ color: bColor || 'inherit' }} className="mt-10">
                <BlockContent blocks={bodyRichText} serializers={serializers} />
              </div>
            ) : null}
            <Button
              className="mt-20 bg-white"
              variant={module.cta.buttonType}
              size="large"
              link={module.cta.link}
              onClick={() => analytics.trackButtonClick(module.cta.link.title)}
            >
              {module.cta.link.title}
            </Button>
            {footer ? (
              <div className="mt-6 text-13 s:text-14" style={{ color: bColor || 'inherit' }}>{footer}</div>
            ) : null}
          </div>
        </div>
      </div>

      {/* background */}
      <div
        className={cx('w-full h-full s:inset-0', {
          'inset-0 absolute': !mobileBgStyle,
          'absolute top-0 left-0': mobileBgStyle,
        })}
      >
        {background?._type === 'solid' || background?._type === 'ombre' ? (
          <>
            <div
              className={cx('w-full h-full', {
                's:hidden': hasResponsiveBackground,
              })}
              style={{ background: getColorStyle(background) }}
            />
            {isScreenSmall && hasResponsiveBackground ? (
              <div
                className={cx('w-full h-full')}
                style={{ background: getColorStyle(desktopBackground) }}
              />
            ) : null}
          </>
        ) : null}
        {background?._type === 'imageAsset' || background?._type === 'videoAsset' ? (
          <>
            <Media
              className={cx({
                's:hidden': hasResponsiveBackground,
              })}
              media={background}
              width={375}
              height={535}
              fill={hasResponsiveBackground}
            />
            {isScreenSmall && hasResponsiveBackground ? (
              <Media
                media={desktopBackground}
                width={1536}
                fill={isScreenSmall}
                position={
                  isScreenLarge
                    ? contentAlignment
                    : contentAlignment === 'left'
                    ? '25%'
                    : contentAlignment === 'right'
                    ? '75%'
                    : 'center'
                }
              />
            ) : null}
            {textMarkTitle ? (
              <span
                className="absolute bottom-20 right-20 z-1 text-12 m:text-16"
                style={{ color: getColorStyle(textMarkColor) }}
              >
                {textMarkTitle}
              </span>
            ) : null}
          </>
        ) : null}
      </div>
    </div>
  )
}
