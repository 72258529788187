import useMedia from 'use-media'
import { screens } from 'lib/constants'
import Button from 'components/Button'
import * as analytics from 'lib/analytics'

export default function EditorialModuleHeader({ title, link }) {
  const isScreenLarge = useMedia({ minWidth: screens.l })
  if (!title) {
    return <></>
  }
  return (
    <header className="border-t border-pattens-blue pt-30 l:pt-40 flex items-center justify-between pb-20 l:pb-45">
      <h2 className="text-24 s:text-28 m:text-32 l:text-40 font-medium leading-130">{title}</h2>
      <Button
        link={
          link ?? {
            _type: 'internalLink',
            reference: {
              _type: 'articleTag',
              slug: 'all',
            },
          }
        }
        variant="outlined"
        size={isScreenLarge ? 'large' : 'small'}
        onClick={() => analytics.trackButtonClick('View All')}
      >
        View All
      </Button>
    </header>
  )
}
